<template>
  <div class="search-style">
    <van-sticky>
      <van-search
        v-model="value"
        shape="round"
        @search="onSearch"
        show-action
        placeholder="搜索NFT"
      >
        <template #left>
          <div class="s-left">
            <van-icon name="arrow-left" size="20" @click="goback" />
          </div>
        </template>
        <template #action>
          <div class="s-right" @click="onSearch(value)">搜索</div>
        </template>
      </van-search>
    </van-sticky>
    <div class="case2">
      <div class="box3 case2-t1">分类</div>
      <div class="case-box">
        <div class="case-list start-center">
          <div
            class="fl-item"
            v-for="i in 7"
            :key="i"
            :style="{
              backgroundImage: `url(${$concatImg('img16')})`,
            }"
          >
            艺术
          </div>
        </div>
      </div>
    </div>
    <div class="box4 start-center">
      <div class="item" :class="active==1?'ac':'no'" @click="changes(1)">
        热门搜索
      </div>
      <div class="item" :class="active==2?'ac':'no'" @click="changes(2)">
        推荐系列
      </div>
    </div>
    <div class="box5 start-center">
      <div class="itembox" v-for="i in 7" :key="i">
        <el-card class="box-card">
          <div class="item between-end" :style="{
            backgroundImage: `url(${$concatImg('img16')})`
          }">
            <img src="@/assets/img/img60.png" class="i1" />
            <div class="t1">名称名称名称</div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      active:1
    };
  },
  methods: {
    changes(e){
      this.active = e
    },
    goback() {
      this.$router.back();
    },
    onSearch(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="less">
.search-style {
  .s-left {
    margin-right: 10px;
  }
  .s-right {
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    margin-left: 10px;
  }
  .case2 {
    width: 100%;
    margin-bottom: 20px;
    .case2-t1 {
      padding: 0 0 0 20px;
    }
    .case-box {
      overflow: auto;
      width: 100%;
      .case-list {
        padding: 0 20px;
        .fl-item{
          flex-shrink: 0;
          width: 102px;
          height: 102px;
          border-radius: 10px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          box-sizing: border-box;
          padding: 15px;
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF;
          margin-right: 25px;
        }
      }
    }
  }
  .box3 {
    font-size: 18px;
    font-weight: bold;
    color: #323232;
    margin: 10px 0 20px;
  }
  .box4{
    padding: 0 28px;
    border-bottom: 1px solid #E1E1E1;
    .item{
      padding: 0 0 15px 0;
      margin-right: 20px;
      font-size: 12px;
      font-weight: bold;
    }
    .ac{
      color: #323232;
      border-bottom: 1px solid #323232;
    }
    .no{
      color: #909090;
    }
  }
  .box5{
    padding: 21px 0 10px;
    flex-wrap: wrap;
    .itembox{
      padding: 0 17px 20px;
      .box-card{
        width: 150px;
      }
      .el-card__body, .el-main{
        padding: 0;
      }
      .item{
        box-sizing: border-box;
        width: 150px;
        border-radius: 10px;
        padding: 75px 10px 15px;
        background-size: 100% 100px;
        background-repeat: no-repeat;
        .i1{
          width: 45px;
          height: 45px;
          border-radius: 3px;
          margin-right: 9px;
        }
        .t1{
          font-size: 12px;
          font-weight: bold;
          color: #323232;
          flex: 1;
        }
      }
    }
  }
}
</style>